import React, { useRef, useEffect } from 'react';
import './../theme/ui/popover.scss';

interface BookPopoverProps {
    show: boolean;
    onClose: () => void;
    position?: 'center' | 'default';
}

const BookPopover: React.FC<BookPopoverProps> = ({ show, onClose, position = 'default' }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, onClose]);

    const shortcutLink: string = 'https://home.shortcutssoftware.com/trendzonebarbershop#/_h/sites/67397/details';
    const facebookLink: string = 'https://www.facebook.com/trendzonebarbershop/';

    const handleOpenLink = (url: string) => {
        window.open(url, '_blank');
    };

    if (!show) return null;

    return (
        <div className="popover-overlay">
            <div className={`popover-container ${position === 'center' ? 'center-position' : ''}`} ref={containerRef}>
                <button className="btn-primary" onClick={() => handleOpenLink(shortcutLink)}>Shortcut</button>
                <button className="btn-primary" onClick={() => handleOpenLink(facebookLink)}>Facebook</button>
                {/* <button className="btn-std" onClick={onClose}>Close</button> */}
            </div>
        </div>
    );
};

export default BookPopover;


