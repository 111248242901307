import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

const Privacy: React.FC = () => {
    return (
        <main>
            <Header />
            <Footer />
            <div className='mx-10 md:mx-40'>
                <h1 className='text-center text-3xl text-dark'>Privacy Policy</h1>
                <p className='text-center'>
                    Privacy Policy

                    At Trendzone Barbershop, we value and respect your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website or utilize our services.

                    Information We Collect:

                    Personal Information: When you make an appointment or sign up for our services, we may collect personal information such as your name, email address, phone number, and billing information.
                    Usage Information: We may collect information about your interactions with our website, including the pages you visit, the links you click, and the duration of your visit.
                    Device Information: We may collect information about the device you use to access our website, such as your IP address, browser type, operating system, and device identifiers.
                    How We Use Your Information:

                    Providing Services: We use your personal information to provide and deliver the services you request, such as scheduling appointments and processing payments.
                    Improving Our Services: We analyze usage data to understand how our website and services are used, identify trends, and improve user experience.
                    Communications: We may use your contact information to send you updates, promotions, and other relevant information about our services. You can opt-out of these communications at any time.
                    Legal Compliance: We may use your information to comply with applicable laws, regulations, or legal processes.
                    Information Sharing:

                    We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating our website and providing our services. These service providers are obligated to maintain the confidentiality of your information and are prohibited from using it for any other purpose.

                    Data Security:

                    We implement security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

                    Your Choices:

                    You have the right to access, update, or delete your personal information. You can also choose to opt-out of certain communications or data collection practices. Please contact us if you wish to exercise any of these rights.

                    Changes to This Privacy Policy:

                    We reserve the right to update or revise this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.

                    Contact Us:

                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [email address] or by mail at [mailing address].

                    Effective Date: April 26, 2024
                </p>
            </div>
        </main>
    );
}

export default Privacy;