import './../theme/comp/footer.scss';
import './../theme/gen/logo.scss';

import instaIcon from './../assets/icons/icon-instagram.svg'

const Footer: React.FC = () => {


    const currentYear = new Date().getFullYear();


    return (
        <footer className="site-footer margin-top-48">

            <div className="site-footer-social-block">
                <a href="https://www.instagram.com/trendzonebarbershops/" target="_blank" rel="noreferrer">
                    <img className='insta-icon' src={instaIcon} alt="Instagram" />
                </a>
                <p className='text-center'>follow us on instagram at <a href="https://www.instagram.com/trendzonebarbershops/" target="_blank" rel="noreferrer">@trendzonebarbershop</a></p>
            </div>

            <div className="site-footer-top-block">
                <div className="site-footer-top-block-logo-section">
                    <div className='logo-std' style={{ '--std-logo-url': `url(${process.env.PUBLIC_URL}/logos/tzlogostandard.svg)` } as React.CSSProperties}></div>
                </div>
                <div className="site-footer-top-block-address-section">
                    <h2>Address</h2>
                    <p>3211 152 St Unit-110, <br />Surrey, BC <br />V3S 3M1</p>
                    <h2>Contact</h2>
                    <p><a href="tel:+16043853000">(604) 385-3000</a></p>
                    <p><a href="mailto:info@trendzonebarbershop.com">info@trendzonebarbershop.com</a></p>
                </div>
                <div className="site-footer-top-block-info-section">
                    <h2>Open Hours</h2>
                    <p>Weekdays</p>
                    <p>9:00 AM - 8:00 PM</p>
                    <p>Saturday</p>
                    <p>9:00 AM - 5:00 PM</p>
                    <p>Sunday & Holidays</p>
                    <p>10:00 AM - 5:00 PM</p>
                </div>
            </div>
            <div className="flex flex-col text-center font-sans margin-top margin-bottom">
                <p>&copy;{currentYear} TRENDZONE BARBERSHOP. All Rights Reserved.</p>
                <p className='small'>Built by &copy;{currentYear} <a href="https://skydash.ca" target="_blank" rel="noreferrer">Skydash Corporation, Canada</a></p>
            </div>
        </footer >
    );
}

export default Footer;