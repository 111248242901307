import React, { useEffect, useState } from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Notebox from '../components/Notebox';

import './../theme/ui/alignment.scss';
import './../theme/comp/services.scss';
import './../theme/ui/text.scss';
import BookTwoPopover from '../popovers/Book-two.popover';

interface Subitem {
    type: string;
    price: string;
    description: string;
}

interface ServiceCategory {
    heading: string;
    showcase: number;
    subitems: Subitem[];
}

type ServicesData = ServiceCategory[];


const Services: React.FC = () => {

    const note: string = "24-hour notice required for cancellations; appointments held for 10 minutes, after which you'll need to wait for the next available slot."
    const lastUpdate: string = "Last updated on September 31, 2024. Price may change without notice or may actually vary."

    const [services, setServices] = useState<ServicesData>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetch('/price-list.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => setServices(data))
            .catch(error => {
                console.error('Error fetching the services data:', error);
                setError(error.message);
            });
    }, []);

    return (
        <main>
            <div className="header-gap"></div>
            <Header solidHeader={true} />
            <h1 className='text-center'>Services & Pricing</h1>

            <Notebox text={note} />

            <div className="services-container">
                {error ? (
                    <div className="error-message text-center">{error}</div>
                ) : (
                    services.map((service, index) => (
                        <ServiceCategoryItem key={index} category={service} />
                    ))
                )}
            </div>

            <Notebox text={lastUpdate} />

            <Footer />
        </main>
    );
}

interface ServiceCategoryItemProps {
    category: ServiceCategory;
}

const ServiceCategoryItem: React.FC<ServiceCategoryItemProps> = ({ category }) => {
    return (
        <div className="service-category">
            <h2 className='text-center'>{category.heading}</h2>
            <div className="service-category-subitems">
                {category.subitems.map((item, index) => (
                    <ServiceItem key={index} item={item} />
                ))}
            </div>
        </div>
    );
}

interface ServiceItemProps {
    item: Subitem;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ item }) => {

    const [popoverOpen, setPopoverOpen] = useState(false);

    return (
        <>
            <div className="service-item text-center" onClick={() => setPopoverOpen(!popoverOpen)}>
                <h3 className='text-uppercase'>{item.type}</h3>
                <p>{item.description}</p>
                <h3>{item.price}</h3>
            </div>
            <BookTwoPopover show={popoverOpen} onClose={() => setPopoverOpen(false)} />
        </>
    );
}

export default Services;