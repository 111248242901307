import React, { useEffect, useState } from 'react';

import './../theme/comp/hero-image.scss';

const HeroImage: React.FC = () => {

    const desktopImage = process.env.PUBLIC_URL + '/images/hero.jpg';
    const mobileImage = process.env.PUBLIC_URL + '/images/hero-mobile.jpg';

    const [heroImage, setHeroImage] = useState<string>(desktopImage);

    useEffect(() => {
        const updateHeroImage = () => {
            if (window.matchMedia('(max-width: 768px)').matches) {
                setHeroImage(mobileImage);
            } else {
                setHeroImage(desktopImage);
            }
        };

        updateHeroImage(); // Initial check
        window.addEventListener('resize', updateHeroImage); // Add event listener for resize

        return () => {
            window.removeEventListener('resize', updateHeroImage); // Cleanup event listener on component unmount
        };
    }, [desktopImage, mobileImage]);

    return (
        <div className='hero-image' style={{ backgroundImage: `url(${heroImage})` }}>
        </div>
    );
}

export default HeroImage;