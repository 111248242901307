import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

const About: React.FC = () => {
  return (
    <main>
      <Header />
      <Footer />
      <h1>About</h1>
    </main>
  );
}

export default About;