import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAJtXj-NsHv-HoNfB4wcx31Vpip2nGu-Cc",
    authDomain: "trendzone-347201.firebaseapp.com",
    projectId: "trendzone-347201",
    storageBucket: "trendzone-347201.appspot.com",
    messagingSenderId: "779736697300",
    appId: "1:779736697300:web:c347ef2b5f0329d510ee41",
    measurementId: "G-MV3NP4X7LL"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { storage };
export { analytics };
