import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

const Giftcards: React.FC = () => {
  return (
    <main>
      <Header />
      <Footer />
      <h1>Giftcards</h1>
    </main>
  );
}

export default Giftcards;