import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

const Terms: React.FC = () => {
    return (
        <main>
            <Header />
            <Footer />
            <div className='mx-10 md:mx-40'>
                <h1 className='text-center text-3xl text-dark'>Terms and Conditions</h1>
                <p className='text-center'>
                    **Terms and Conditions**

                    Welcome to Trendzone Barbershop! These Terms and Conditions govern your use of our website and services. By accessing or using our website and services, you agree to comply with these terms. Please read them carefully.

                    **1. Services:**

                    Trendzone Barbershop offers grooming services for men, including haircuts, shaves, beard trims, and other related services. By scheduling an appointment with us, you agree to abide by our service policies and procedures.

                    **2. Appointment Booking:**

                    You can book appointments through our website or by contacting us directly. Appointments are subject to availability, and we recommend booking in advance to secure your preferred time slot.

                    **3. Cancellation Policy:**

                    We understand that plans may change. If you need to cancel or reschedule your appointment, please notify us at least 24 hours in advance. Failure to do so may result in a cancellation fee.

                    **4. Payment:**

                    Payment for services is due at the time of appointment unless otherwise arranged. We accept cash, credit/debit cards, and other forms of electronic payment.

                    **5. Liability:**

                    While we strive to provide excellent service, Trendzone Barbershop shall not be liable for any damages, injuries, or losses arising from your use of our services. You agree to use our services at your own risk.

                    **6. Privacy:**

                    We respect your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for details on how we collect, use, and protect your data.

                    **7. Intellectual Property:**

                    All content on our website, including text, graphics, logos, and images, is the property of Trendzone Barbershop and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.

                    **8. Amendments:**

                    We reserve the right to amend these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our website and services constitutes acceptance of the amended terms.

                    **9. Governing Law:**

                    These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].

                    **Contact Us:**

                    Change of Pricing:

                    Trendzone Barbershop reserves the right to modify our pricing for services at any time. Any changes to pricing will be communicated to customers in advance via our website or other means of communication. By continuing to use our services after the implementation of new pricing, you agree to the updated rates. We strive to provide fair and transparent pricing for all our services. If you have any questions or concerns about pricing changes, please don't hesitate to contact us.

                    If you have any questions or concerns about these Terms and Conditions, please contact us at [email address] or by mail at [mailing address].

                    Effective Date: April 26, 2024
                </p>
            </div>
        </main>
    );
}

export default Terms;