import React from 'react';
import './../theme/comp/notebox.scss';
import './../theme/ui/alignment.scss';

import infoIcon from './../assets/icons/icon-info.svg'

interface NoteboxProps {
    text: string;
}


const Notebox: React.FC<NoteboxProps> = ({ text }) => {
    return (
        <div className='note-box-container'>
            <div className="note-box-container-icon">
                <img src={infoIcon} alt="" />
            </div>
            <div className="note-box-container-message">
                <p>{text}</p>
            </div>
        </div>
    );
}

export default Notebox;