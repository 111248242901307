import React from 'react';

import './../theme/comp/award.scss';

import awardIcon from './../assets/icons/award.svg'


const Awards: React.FC = () => {
    return (
        <div className='award-container'>
            <div className="award-box">
                <img src={awardIcon} alt="" />
                <h4 className='text-center'>New business of the year 2024</h4>
                <p className='text-center'>South Surrey & White Rock</p>
                <p className='text-center'>Chamber of Commerce</p>
            </div>
        </div>

    );
}

export default Awards;