import React, { useEffect, useState } from 'react';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../firebase';

import './../theme/comp/gallery.scss';

const Gallery: React.FC = () => {

    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        const fetchImages = async () => {
            const galleryRef = ref(storage, 'gallery/');
            try {
                const result = await listAll(galleryRef);
                const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
                const urls = await Promise.all(urlPromises);
                setImages(urls);
            } catch (error) {
                console.error("Error fetching images: ", error);
            }
        };
        fetchImages();
    }, []);

    return (
        <div>
            <div className="gallery-container grid">
                {images.map((image: any, index: number) => (
                    <div key={index}>
                        <img src={image} alt={`Gallery item ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;